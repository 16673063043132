<template>
  <w-app>
    <section>
      <img alt="Vue logo" src="./assets/logo.png" />
      <HelloWorld msg="Welcome to Your Vue.js App" />
    </section>

    <!-- Also try to add a button! -->
    {{ store.counter }} {{ store.doubleCount }}
    <w-button @click="store.increment">My Button</w-button>
  </w-app>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { useInitStore } from "@/store";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
  data() {
    return {
      store: useInitStore(),
    };
  },
  created() {
    console.log("created", this.$store);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
