import { createApp } from "vue";
import { createPinia } from "pinia";
import WaveUI from "wave-ui";
import "wave-ui/dist/wave-ui.css";
import App from "./App.vue";
import router from "@/router";

const app = createApp(App);

app.use(createPinia());
app.use(router);

new WaveUI(app, {
  // Some Wave UI options.
});

app.mount("#app");
